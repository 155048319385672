<script>
import { authMethods } from '@state/helpers/auth'
import passwordData from '@utils/passwordData'
import LanguageSelector from '@components/language-selector/language-selector'
import {
  passwordDigitValidation,
  passwordLettersValidation,
  requiredValidation,
  passwordValidations,
  passwordMatchValidation,
  numberRangeValidation,
} from '@utils/inputValidation'
import eventBus from '@src/event-bus'
import i18n from '@src/i18n'

export default {
  components: {
    LanguageSelector,
  },
  data() {
    return {
      newPassword: {
        ...passwordData,
        rules: [
          (value) =>
            passwordValidations(
              value,
              this.newPassword.validations,
              'isPasswordDigitValid',
              () => passwordDigitValidation(value)
            ),
          (value) =>
            passwordValidations(
              value,
              this.newPassword.validations,
              'isPasswordLettersValid',
              () => passwordLettersValidation(value)
            ),
          (value) =>
            passwordValidations(
              value,
              this.newPassword.validations,
              'isPasswordRangeValid',
              () => numberRangeValidation(value, 7, 50)
            ),
        ],
      },
      confirmPassword: {
        ...passwordData,
        rules: [(value) => requiredValidation(value)],
      },
      valid: false,
    }
  },
  mounted() {
    eventBus.$on('languageChanged', () => {
      this.$refs.form.validate()
    })
    const uuid = this.$route.params.id
    const regex =
      /([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}){1}/

    if (!regex.test(uuid)) {
      eventBus.$emit('growl', {
        title: 'Error:',
        description: i18n.t('INVALID_UUID'),
        type: 'error',
      })
    }
  },
  beforeDestroy() {
    eventBus.$off('languageChanged')
  },
  methods: {
    ...authMethods,
    submit() {
      const token = this.$route.params.id
      if (this.$refs.form.validate()) {
        return this.createPassword({
          newPassword: this.newPassword.value,
          confirmPassword: this.confirmPassword.value,
          token,
        })
      }
    },
    passwordMatch() {
      return passwordMatchValidation(
        this.newPassword.value,
        this.confirmPassword.value
      )
    },
  },
}
</script>

<template>
  <div>
    <h1 class="createPassword-title">{{ $t('CREATE_PASSWORD') }}</h1>
    <p class="createPassword-subtitle">{{ $t('CREATE_PASSWORD_INFO') }}</p>
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-row>
        <v-text-field
          v-model="newPassword.value"
          :append-icon="newPassword.show ? 'visibility' : 'visibility_off'"
          :rules="newPassword.rules"
          :type="newPassword.show ? 'text' : 'password'"
          :label="$t('NEW_PASSWORD')"
          hide-details
          outlined
          name="newPassword"
          password
          required
          @click:append="newPassword.show = !newPassword.show"
        ></v-text-field>
        <ul class="validationMessages">
          <li v-for="item in newPassword.validations" :key="item.index">
            <span
              :class="{
                error: item.valid === 'invalid',
                valid: item.valid === 'valid',
                validationMessage: true,
              }"
            >
              {{ $t(item.message, item.params) }}
            </span>
          </li>
        </ul>
      </v-row>
      <v-row>
        <v-text-field
          v-model="confirmPassword.value"
          :append-icon="confirmPassword.show ? 'visibility' : 'visibility_off'"
          :rules="confirmPassword.rules"
          :type="confirmPassword.show ? 'text' : 'password'"
          :label="$t('CONFIRM_PASSWORD')"
          outlined
          :error-messages="passwordMatch()"
          password
          required
          name="confirmPassword"
          @click:append="confirmPassword.show = !confirmPassword.show"
        ></v-text-field>
      </v-row>

      <v-btn
        :disabled="!valid"
        class="btn primary-btn createPassword-btn center"
        color="success"
        type="submit"
      >
        {{ $t('CREATE_PASSWORD') }}
      </v-btn>
    </v-form>
    <LanguageSelector />
  </div>
</template>

<style lang="scss" scoped>
@import '@design';

.createPassword-title {
  margin-bottom: 20px;
}

.createPassword-subtitle {
  margin-bottom: 45px;
}

.createPassword-btn {
  margin-top: 45px;
  margin-bottom: 20px;
}

.validationMessages {
  padding-left: 10px;
  margin-bottom: 20px;
  text-align: left;

  li {
    display: block;
  }
}
.validationMessage {
  font-size: 12px;
  color: $grey;

  &.error {
    color: $alert-error;
  }

  &.valid {
    text-decoration: line-through;
  }
}
</style>
