<script>
import Layout from '@layouts/login/login'
import i18n from '@src/i18n'
import CreatePassword from '@components/create-password/create-password'

export default {
  page: {
    title: i18n.t('CREATE_PASSWORD'),
    meta: [{ name: 'description', content: `Create password` }],
  },
  components: { Layout, CreatePassword },
}
</script>

<template>
  <Layout>
    <CreatePassword />
  </Layout>
</template>
